import React from 'react';
import { useTranslation } from 'react-i18next';

export default function WarningMessege() {
  const { t } = useTranslation();

  return (
    <div>
      <h3 style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}>{t('alertMsg3')}</h3>
    </div>
  )
}
