import React, { useState } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';

export default function DashboardChart({ topFive }) {
  const { t } = useTranslation();
  console.log(topFive, "ab");
  const [data, setData] = useState(topFive[0])
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: []
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          //return Math.round(value);
          return value.toFixed(2);
        }
      }
    },
    noData: {
      text: "Test(s) not found",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: '#aaaaaa',
        background: 'transparent',
      },
    },
  })
  const [series, setSeries] = useState([
    {
      name: "Your Score",
      data: []
    }
  ])

  React.useEffect(() => {
    var length = topFive.length;
    const yourscore = [];
    const averagescore = [];
    const topscore = [];
    const categoryName = [];

    for (let a = 0; a < length; a++) {
      yourscore.push(topFive[a].your)
      averagescore.push(topFive[a].average)
      topscore.push(topFive[a].top)
      categoryName.push(topFive[a].code)
    }
    setSeries(
      [
        {
          name: t("you"),
          data: yourscore
        },
        {
          name: t("avg"),
          data: averagescore
        },
        {
          name: t("top"),
          data: topscore
        }
      ]
    );
    setOptions(
      {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: categoryName
        }
      }
    )
  }, [topFive])

  return (
    <div>
      {
        console.log(options, 'options123')
      }
      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
      />
    </div>
  )
}
