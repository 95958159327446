import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dashboardStyle from "../../assets/dashboardStyle";
import loginPageStyle from "../../assets/loginPageStyle";
import { LoginContext } from "../../contexts/LoginContext";
import { TestContext } from "../../contexts/TestContext";
//import GetTestDetails from "../../services/GetTestDetails";
import newStyles from '../../styles/TestPlayer.module.css';
import Button from "../CustomButtons/Button";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import TimerView from "../Player/TimerView";
import ConcludeScreen from "../Questions/ConcludeScreen";
import FeedbackScreens from "../Questions/FeedbackScreens";
import QuestionWrapperNew from "../Questions/QuestionWrapperNew";
import WelcomeScreenNew from "../Questions/WelcomeScreenNew";
import TOCDwawer from "./TOCDwawer";

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
export default function TestPlayer(props) {
  //TODO
  console.log('hi test player')
  const { t } = useTranslation();
  const classes = useStyles();
  const { showToC } = useContext(LoginContext);

  let testData = props.tests;
  let testAttemptDetailsData = props.testAttemptDetails;
  let showSubmit = props.showSubmit;
  let Finish = props.onFinish;
  // const getTestDetails = async () => {
  //   const testDetail = await GetTestDetails.getTestDetails(props.userId, props.testId, props.attempt)
  //   testDetail.currentPageIndex = 0;
  //   setTestAttemptDetails(testDetail);
  //   setTest(JSON.parse(testDetail.attemptDetails).value);
  // }
  // useEffect(() => {
  //   getTestDetails();
  // }, [])

  const [testAttemptDetails, setTestAttemptDetails] = useState({ ...testAttemptDetailsData, currentPageIndex: 0 });
  const [test, setTest] = useState(testData);
  const [selectEvent, setSelectEvent] = useState(true)
  const [markForReview, setMarForkReview] = useState(); // will delete it later when this is used in Bookmark component and this is used common in both player
  const [startQuestionTime, setStartQuestionTime] = useState(new Date());
  const [testTFlag, setTestTFlag] = useState(false);
  const [pulledQuestions, setPulledQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  //const [second, setSecond] = useState(test.duration * 60)
  const [isSubmit, setIsSubmit] = useState(false);
  const [timeOutSubmit, setTimeOutSubmit] = useState(false);
  const [remainingQuestions, setRemainingQuestions] = useState(0);
  const [bookmarked, setBookMarked] = useState(0);
  const [tatalQuestionCount, setTotalQuestionCount] = useState(0);
  const [toconcludeScreen, setToConcludeScreen] = useState('');

  // useEffect(() => {
  //   if (testTFlag == true) {
  //     const intervalId = setInterval(() => {
  //       if (test.duration !== null && second === 0) {
  //         setTimeOutSubmit(true);
  //         setInterval()
  //       } else {
  //         setSecond(second - 1)
  //       }
  //     }, 1000);
  //     return () => clearInterval(intervalId);
  //   }
  // }, [testTFlag, second])

  const startButtonTime = (time, flag) => {
    setStartQuestionTime(time);
    setTestTFlag(flag);
  }

  const onNextClick = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }
  const onPrevClick = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1)
  }

  const getQuestionCount = (questions) => {
    setPulledQuestions(questions);
  }
  const onSubmitClick = () => {
    setIsSubmit(true);
  }
  useEffect(() => {
    let unsolvedQuestions = [];
    let markedForReviewCount = [];
    let totalQuestionCount = [];
    for (let j = 0; j < test.pools["$values"].length; j++) {
      for (let i = 0; i < test.pools["$values"][j].questions["$values"].length; i++) {
        totalQuestionCount.push(test.pools["$values"][j].questions["$values"][i].id);

        if (test.pools["$values"][j].questions["$values"][i].response == null || test.pools["$values"][j].questions["$values"][i].response.length === 0) {
          unsolvedQuestions.push(test.pools["$values"][j].questions["$values"][i].id)
        }
        if (test.pools["$values"][j].questions["$values"][i].markForReview == true) {
          markedForReviewCount.push(test.pools["$values"][j].questions["$values"][i].id)
        }
      }
    }
    setRemainingQuestions(unsolvedQuestions.length);
    setBookMarked(markedForReviewCount.length);
    setTotalQuestionCount(totalQuestionCount.length);
  }, [selectEvent])
  console.log(selectEvent, 'event123')
  const onHomePage = (a) => {
    setToConcludeScreen(a);
    console.log(a);
  }
  return (
    <div className={classes.wrapper}>
      <div
        style={{
          // modal Background BSS121
          background: "#ffffff ",
          //padding: "120px 0",
          position: "relative",
          minHeight: "70vh",
          display: "flex!important",
          margin: "0",
          border: "0",
          color: "white",
          alignItems: "center",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "100%",
        }}
      >
        {test && (
          <TestContext.Provider
            value={{ setTestTFlag, currentQuestionIndex, setCurrentQuestionIndex, markForReview, setMarForkReview, test, testAttemptDetails, setTestAttemptDetails, selectEvent, setSelectEvent }}
          >
            <GridContainer justifyContent="center" style={{ width: "calc(100% + 15px)" }}>
              <GridItem xs={12} sm={12} md={12}>
                {/* Head of Player */}
                <div>

                </div>

                {/* Body of player */}
                <GridContainer style={{ float: 'right' }}>
                  <GridItem xs={12} sm={12} md={9} lg={12} className={newStyles.showOnMobile} style={{ maxHeight: '499px', overflowY: 'auto', overflowX: 'auto' }}>
                    <div className={newStyles.TOCHover}>
                      {
                        test.navigation == 2 && showToC ?
                          <TOCDwawer tatalQuestionCount={tatalQuestionCount} remainingQuestions={remainingQuestions} bookmarked={bookmarked} QuestionPerPage={test.questionsPerPage} />
                          : null
                      }
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={test.navigation == 2 && showToC ? 9 : 12} lg={test.navigation == 2 && showToC ? 9 : 12} className={newStyles.questionBody}>
                    {
                      testAttemptDetails.currentPageIndex == 0 && (
                        <WelcomeScreenNew
                          message={test.welcomeMessage}
                          startButtonTime={startButtonTime}
                        />
                      )
                    }
                    {testAttemptDetails.currentPageIndex == 1 && (
                      <QuestionWrapperNew
                        attempt={props.attempt}
                        preview={props.preview}
                        isCourse={props.isCourse}
                        submitFlag={isSubmit}
                        setSubmitFlag={setIsSubmit}
                        timeOutFlag={timeOutSubmit}
                        currentQuestionIndex={currentQuestionIndex}
                        getQuestionCount={getQuestionCount}
                        testStartedTime={startQuestionTime}
                        testStartFlag={testTFlag}
                        remainingQuestions={remainingQuestions}
                        questionObj={props.questionObj}
                      />
                    )}
                    {testAttemptDetails.currentPageIndex == 2 && (
                      test.showResult == 2 ?
                        <FeedbackScreens
                          message={test.feedbackFail}
                          testAttemptDetails={testAttemptDetails}
                          test={test}
                          score={testAttemptDetails && testAttemptDetails.actualScore}
                          totalScore={testAttemptDetails.totalScore}
                          onHomePage={onHomePage}
                        /> : testAttemptDetails.currentPageIndex = 3
                    )}
                    {testAttemptDetails.currentPageIndex == 3 && (
                      <ConcludeScreen message={test.concludeMessage} toconcludeScreen={toconcludeScreen} onFinish={Finish} />
                    )}
                  </GridItem>
                  {console.log(test.navigation, 'navigationkk')}
                  {test.navigation == 2 && showToC ?
                    <GridItem xs={12} sm={12} md={3} lg={3} className={newStyles.showOnWeb} >
                      <TOCDwawer tatalQuestionCount={tatalQuestionCount} remainingQuestions={remainingQuestions} bookmarked={bookmarked} QuestionPerPage={test.questionsPerPage} />
                    </GridItem>
                    : null
                  }
                </GridContainer>

                {/* footer of player */}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div p={1} className={newStyles.footer4Actions} justifyContent={"center"}>
                      <div style={{ margin: '3px 0px 0px 0' }}>
                        {
                          <TimerView setTimeout={() => { setTimeOutSubmit(true) }} showTimer={test.showTimer} duration={test.duration} testTFlag={testTFlag} />
                        }

                        {!testAttemptDetails.isSubmited == true && test && testAttemptDetails.currentPageIndex == 1 && test.navigation ? (
                          <Button
                            // justIcon
                            round
                            color="primary"
                            onClick={onPrevClick}
                            style={{ padding: '7px 7px' }}
                            disabled={currentQuestionIndex <= 0 || test.navigation == 1}
                          >
                            <ArrowBackIcon />
                          </Button>
                        ) : null
                        }
                        {
                          !testAttemptDetails.isSubmited == true && test && testAttemptDetails.currentPageIndex == 1 && (
                            <span style={{ marginInline: '5px', color: 'orange', fontWeight: '700', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '12px' }}>
                              {/* {t('page')} */}
                              {currentQuestionIndex + 1} {t('of')} {pulledQuestions.length}
                            </span>
                          )
                        }
                        {
                          !testAttemptDetails.isSubmited == true && test && pulledQuestions.length > 0 && (
                            <Button
                              // justIcon
                              round
                              color="primary"
                              onClick={onNextClick}
                              style={{ padding: '7px 7px' }}
                              disabled={currentQuestionIndex >= pulledQuestions.length - 1}
                            >
                              <ArrowForwardIcon />
                            </Button>
                          )
                        }
                        <span style={{ float: 'right', marginRight: '1%' }}>
                          {!testAttemptDetails.isSubmited == true && test && pulledQuestions.length > 0 && showSubmit ? <Button color="primary" style={{ padding: '6px 12px' }} round onClick={onSubmitClick}>
                            {t("submit")}
                          </Button> : (!testAttemptDetails.isSubmited == true && test && pulledQuestions.length > 0 && currentQuestionIndex >= pulledQuestions.length - 1 && pulledQuestions.length > 0 ?
                            <Button style={{ padding: '6px 12px' }} color="primary" round onClick={onSubmitClick}>
                              {t("submit")}
                            </Button> : null)
                          }
                        </span>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </TestContext.Provider>
        )}
      </div>
    </div>
  );
}
