import { Alert, Snackbar } from "@mui/material";
import { Button, Col, Form, Input, Row, message } from 'antd';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { configUrl } from "../config";
import { LoginContext } from "../contexts/LoginContext";


const ChangePassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { UserId, providerKey } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severty, setSeverty] = useState('success');

  const key = 'pj23vs7nycq18uew';
  let secretKey = CryptoJS.enc.Utf8.parse(key);

  const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
  }
  const decryptPassword = (encryptedPassword, secretKey) => {

    let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedText, 'decryptedone');
    return decryptedText;
    // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    // return bytes.toString(CryptoJS.enc.Utf8);
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    //Will call this API through service (Saurabh)
    try {
      let oldPass = encryptPassword(values.currentPassword, secretKey)
      let newPass = encryptPassword(values.newPassword, secretKey)
      let confirmPass = encryptPassword(values.confirmPassword, secretKey)
      const response = await axios.post(`${configUrl.AssessmentServer}/api/Counselling/changePassword/${UserId}`, {
        oldPassword: oldPass,
        newPassword: newPass,
        confirmPassword: confirmPass
      });
      if (response.data.message) {
        if (response.data.status == true) {
          console.log("Resppnse of passwoords", response.data);
          message.success(response.data.message);
          setOpen(true);
          setSeverty('success');
          setAlertMessage(response.data.message)

          setTimeout(() => {
            setOpen(false);
            sessionStorage.clear();
            sessionStorage.setItem("login", false);
            navigate(`/${providerKey}`);
          }, 3000);
          console.log("User passwordchanges")
        } else {
          message.error(response.data.message);
          setOpen(true);
          setSeverty('error');
          setAlertMessage(response.data.data.error)
        }
        form.resetFields();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while changing password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Row justify="center">
        <Col xs={24} sm={16} md={12} lg={8} style={{ textAlign: "left" }}>
          <div style={{ padding: '20px', marginTop: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '6px' }}>
            <h2 style={{ textAlign: 'center', marginTop: '0px', marginBottom: '20px' }}>Change Password</h2>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
              }}
            >

              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[{ required: true, message: 'Please enter your current password' }]}
              >
                <Input.Password size="middle" />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[{ required: true, message: 'Please enter your new password' }]}
              >
                <Input.Password size="middle" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={['newPassword']}
                rules={[
                  { required: true, message: 'Please confirm your new password' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match'));
                    },
                  }),
                ]}
              >
                <Input.Password size="middle" onPaste={(e) => e.preventDefault()} />
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <Button style={{
                  backgroundColor: "#EC9706",
                  color: "#ffff",
                  marginBottom: "18px",

                }} htmlType="submit" loading={loading}>
                  Set Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          severity={severty}
          onClose={handleClose}
          sx={{ fontSize: "15px", width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ChangePassword;
