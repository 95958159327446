import { Tooltip } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../contexts/LoginContext';
import styles from '../styles/Dashboardtable.module.css';

export default function DashboardTable({ topFive }) {
    const { t } = useTranslation();

    const { UserId } = useContext(LoginContext);
    console.log(topFive, 'topFive123')
    let a = 289.117647;
    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <th className={styles.HeadLeft}>{t('code')}</th>
                        <th className={styles.HeadLeft}>{t('testname')}</th>
                        <th className={styles.Head}>{t('you')}</th>
                        <th className={styles.Head}>{t('avg')}</th>
                        <th className={styles.Head}>{t('top')}</th>

                    </tr>
                    {
                        topFive && topFive.length > 0 ? topFive.map((i, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: 'left' }}>{i.code}</td>
                                <td style={{ textAlign: 'left', maxWidth: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <Tooltip title={i.name} placement="bottom" arrow={false} style={{ fontSize: "1em" }}>
                                        <span>{i.name}</span>
                                    </Tooltip></td>
                                <td>{i?.your?.toFixed(2)}</td>
                                <td>{i?.average?.toFixed(2)}</td>
                                <td>{i?.top?.toFixed(2)}</td>
                            </tr>
                        )) :
                            <tr className={styles.spanrow}>
                                <td colspan="5">
                                    <h5 style={{ marginTop: '20%' }}>Test(s) not found</h5>
                                </td>
                            </tr>

                    }
                </tbody>
            </table>
        </div>
    )
}
