
import { Box, Card, CircularProgress, Tooltip, makeStyles, useTheme } from "@material-ui/core";
import { PlayArrow, Visibility } from "@material-ui/icons";
import { AnalyticsRounded } from "@mui/icons-material";
import { useMediaQuery } from '@mui/material';
import axios from "axios";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { configUrl } from "../config";
import { LoginContext } from "../contexts/LoginContext";
import useGetAttempCount from "../resultHooks/useGetAttempCount";
import styles from '../styles/testviewer.module.css';
import useGetAssignedTest from "../testsHooks/useGetAssignedTests";
import UseGetAttemptDetails from "../testsHooks/useGetAttemptDetails";
import Analytics from "./Analytics";
import CustomModal from "./CustomModal/CustomModal";
import CustomModalNew from "./CustomModal/CustomModalNew";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import TestPlayerNew from "./Player/TestPlayerNew";
import EvaluationQuestionWrapper from "./Questions/EvaluationQuestionWrapper";

const useStyles = makeStyles((theme) => ({
  enabledButton: {
    backgroundColor: '#cfcfcf',
    color: 'white'
  },
  defaultButton: {
    backgroundColor: '#42a5f5',
  },
  view: {
    backgroundColor: '#ff9800',
    borderRadius: 10,
    color: '#ffff',
    border: 'none',
    whiteSpace: 'nowrap',
    padding: 3
  },
  disablebtn: {
    backgroundColor: '#cfcfcf',
    borderRadius: 10,
    color: '#ffff',
    border: 'none',
    whiteSpace: 'nowrap',
    padding: 3
  },
  analyticsbtn: {
    backgroundColor: '#198754',
    borderRadius: 10,
    color: '#ffff',
    border: 'none',
    whiteSpace: 'nowrap',
    padding: 3,
    margin: 10
  },
  disabledanalyticsbtn: {
    backgroundColor: '#cfcfcf',
    borderRadius: 10,
    color: '#ffff',
    border: 'none',
    whiteSpace: 'nowrap',
    padding: 3,
    margin: 10
  },
  tooltip: {
    fontSize: "1em",
  },
}));

function TestViewer(props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const classes = useStyles();
  const { user, emailC, status, alertFlag, setAlertFlag, assessment, testSeriesObj, setShowTOC, UserId, testSeriesCardStatus, topicId, setTestSeriesObj } = useContext(LoginContext);
  //const { data: userDetails, mutate: refetch1 } = useGetUserByUserName();

  const attempt = useGetAttempCount();
  console.log(UserId, 'userDetails123')

  const { data: testDetails, isLoading, refetch } = useGetAssignedTest(UserId ?? null, status);

  const [showTest, setShowTest] = React.useState(false);
  const [testIndex, setTestIndex] = React.useState(0);

  const [testPlayerModal, setTestPlayerModal] = React.useState(null);
  const [evaluationModel, setEvaluationModel] = React.useState(null);

  // React.useEffect(() => {
  //   refetch1(emailC);
  // }, []);
  const getSeriesdta = async () => {
    console.log(topicId, 'topicID')
    let getSeries = await axios.get(`${configUrl.AssessmentServer}/api/Tests/getCourseAssignedTests/${UserId}/${testSeriesCardStatus}/${topicId}`)
    setTestSeriesObj(getSeries.data["$values"]);
  }
  React.useEffect(() => {
    refetch();
    getSeriesdta();
  }, [testPlayerModal])
  const getAttempts = async (id) => {
    let data = await attempt.mutateAsync({
      userId: user,
      testId: id,
    });

    console.log("data", data);
    return data;
  };

  const showTestModelNew = async (i, id, a, testName) => {
    console.log('Click1')
    setShowTOC(false);
    const assessmentDetail = await UseGetAttemptDetails(UserId ?? null, id, a)
    console.log(assessmentDetail, "assessmentDetail123");
    let TestProp = JSON.parse(assessmentDetail?.testDetails?.attemptDetails);
    console.log(TestProp, "TestProp");
    let rtest = TestProp.pools["$values"].map((x, i) => {
      x.showTOCBtn = true;
      x.showTimer = true;
      x.showSubmit = true;
      return x;
    });
    console.log('rtest', rtest)

    setAlertFlag(true)
    setTestPlayerModal(
      <CustomModalNew
        modal={true}
        setModal={hideTestModal}
        content={
          <TestPlayerNew
            tests={JSON.parse(assessmentDetail?.testDetails?.attemptDetails)}
            testAttemptDetails={assessmentDetail?.testDetails}
            questionObj={JSON.parse(assessmentDetail?.questionObj)}
            testId={id}
            userId={UserId ?? null}
            attempt={a}
            isCourse={false}
            showTimer={rtest && rtest[0].showTimer}
            showSubmit={rtest && rtest[0].showSubmit}
            onFinish={hideTestModal}
            preview={false}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideTestModal}
        showAlertAllowed={true}
        title={testName}
        maxWidth="lg"
        fullWidth={false}
        hideTitle={true}
        // fullScreen={true}
        shouldCloseOnOverlayClick={false}
        hideFooter={true}

      />
    );
  };
  const hideTestModal = (event, reason) => {
    if (reason) console.log("reason", reason);
    setTestPlayerModal(null);
  };
  const showEvaluationModel = (id, testName) => {
    console.log('Click2')
    setEvaluationModel(
      <CustomModal
        modal={true}
        setModal={hideEvaluationModel}
        content={
          <EvaluationQuestionWrapper
            testId={id}
            userId={UserId ?? null}
            isCourse={false}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideEvaluationModel}
        title={`${testName} ${t('evaluation')}`}
        maxWidth="lg"
        hideTitle={true}
        fullScreen={true}
        fullWidth={false}
      />
    );
  };
  const showAnalytics = async (lOid, testName) => {
    console.log('Click3')
    setEvaluationModel(
      <CustomModal
        modal={true}
        setModal={hideEvaluationModel}
        content={
          <Analytics
            lOid={lOid}
            studentId={UserId ?? null}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideEvaluationModel}
        title={`${testName}  ${t('analytics')}`}
        maxWidth="lg"
        hideTitle={true}
        fullScreen={true}
        fullWidth={false}
      />
    )
  }

  const hideEvaluationModel = (event, reason) => {
    if (reason) console.log("reason", reason);
    setEvaluationModel(null);
  };

  const hidePlayer = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowTest(false);
  };

  const getCurrentTest = (i) => {
    let currentTest = testDetails[i].data;
    return JSON.parse(currentTest).value;
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={11}>
              {/* TODO */}
              <h4 className={styles.notificationHead}>{t('myAssignedTests')}</h4>
              {
                isLoading == true ?
                  <Box style={{ color: '#FF802B' }}>
                    <CircularProgress style={{ color: '#FF802B' }} />
                    <h4 >{t('loading')}</h4>
                  </Box>
                  :
                  assessment == true ?
                    testDetails && testDetails.length > 0 && testDetails
                      .filter((x) => {
                        let dateDiff = new Date() - new Date(x.schedule);
                        console.log("dateDiff", dateDiff);
                        return dateDiff > 0;
                      }).map((x, i) => (
                        <Card className={styles.card}>
                          <div className={x.showResult === 2 ? (x.result == undefined ? styles.floatingHeadWait : x.result === 1 ? styles.floatingHeadFail : styles.floatingHeadPass) : styles.floatingHeadWait}>
                            {console.log("x.showResult:", x.showResult, "x.result:", x.result)}
                            {x.showResult === 2 ? (x.result == undefined ? 'ToDo' : x.result === 1 ? t('failed') : t('passed')) : t('awaited')}
                            <br />
                            {x.showResult == 2 ? x.actualScore ?? '--' : '--'}/{x.totalScore ?? 0}

                          </div>

                          <h4 className={styles.notificationTextTitle}>{isMobile ? x.name?.substr(0, 13) : x.name}<span style={{ float: 'right', marginInline: '1px' }}>
                            <Tooltip classes={{ tooltip: classes.tooltip }} title="Play">
                              <button
                                className={x.maxAttempts <= x.attemptNumber ?? 0 ? classes.enabledButton : classes.defaultButton}
                                style={{ borderRadius: '10px', padding: '0px 7px 0px 7px', color: 'white', border: 'none', margin: '0px 6px 0px 0px', whiteSpace: 'nowrap' }}
                                onClick={() => {
                                  setTestIndex(i);
                                  showTestModelNew(
                                    i,
                                    x.testId,
                                    (x.attemptNumber ?? 0) + 1,
                                    x.name,
                                  );
                                }}
                                disabled={x.maxAttempts <= x.attemptNumber ?? 0}>
                                {x.maxAttempts <= x.attemptNumber ?? 0
                                  ? <PlayArrow />
                                  : <PlayArrow />}
                              </button>
                            </Tooltip>
                            <Tooltip classes={{ tooltip: classes.tooltip }} title="View answer script">
                              <buton
                                className={x.attemptNumber <= 0 || x.showResult == 1 ? classes.disablebtn : classes.view}
                                onClick={x.attemptNumber <= 0 || x.showResult == 1 ? '' : () => showEvaluationModel(x.testId, x.name)}

                              >
                                <Visibility style={{ margin: '3px', marginBottom: '6px' }} />
                              </buton>
                            </Tooltip>
                            <Tooltip classes={{ tooltip: classes.tooltip }} title="Analytics">
                              <buton
                                className={x.attemptNumber <= 0 || x.showResult == 1 ? classes.disabledanalyticsbtn : classes.analyticsbtn}
                                disabled={x.attemptNumber <= 0}
                                onClick={x.attemptNumber <= 0 || x.showResult == 1 ? '' : () => showAnalytics(x.testId, x.name)}
                              >
                                <AnalyticsRounded style={{ margin: '3px', marginBottom: '6px' }} />
                              </buton>
                            </Tooltip>
                            {/* 
                            {
                              x.maxAttempts <= x.attemptNumber ?? 0 ?
                                <PlayCircleFilled fontSize="large" style={{ color: '#cfcfcf', marginInline: '3px' }} /> :
                                <PlayCircleFilled onClick={() => { setTestIndex(i); showTestModelNew(i, x.testId, (x.attemptNumber ?? 0) + 1, x.name) }} fontSize="large" style={{ color: '#42a5f5', marginInline: '3px' }} />
                            }
                            {
                              x.attemptNumber <= 0 || x.showResult == 0 ?
                                <Visibility fontSize="large" style={{ color: '#cfcfcf', marginInline: '3px' }} /> :
                                <Visibility onClick={() => showEvaluationModel(x.testId, x.name)} fontSize="large" style={{ color: '#ff9800', marginInline: '3px' }} />
                            }
                            {
                              x.attemptNumber <= 0 || x.showResult == 0 ?
                                <AnalyticsRounded fontSize="large" style={{ color: '#cfcfcf', marginInline: '3px' }} /> :
                                <AnalyticsRounded onClick={() => showAnalytics(x.testId, x.name)} fontSize="large" style={{ color: '#198754', marginInline: '3px' }} />

                            } */}


                          </span></h4>
                          <h5 className={styles.notificationText}><small> {t("lastAttempt")}- {!x.attemptedOn || x.attemptedOn === null || x.attemptedOn === undefined ? <span style={{ fontStyle: 'italic', color: '#0EB6CA' }}> {t("YetToAttempt")}</span> : <Moment style={{ fontStyle: 'italic', color: '#0EB6CA' }} format="DD-MMM-YYYY">{x.attemptedOn}</Moment>}, <span>{t("attempts")}- <span style={{ fontStyle: 'italic', color: '#0EB6CA' }}> {x.attemptNumber ?? 0}/{x.maxAttempts ?? 1}</span></span> </small></h5>

                        </Card>
                      )) :
                    testSeriesObj.length > 0 ?
                      testSeriesObj && testSeriesObj
                        .filter((x) => {
                          let dateDiff = new Date() - new Date(x.schedule);
                          console.log("dateDiff", dateDiff);
                          return dateDiff > 0;
                        }).map((x, i) => (
                          <Card className={styles.card}>
                            <div className={x.showResult === 2 ? (x.result == undefined ? styles.floatingHeadWait : x.result === 1 ? styles.floatingHeadFail : styles.floatingHeadPass) : styles.floatingHeadWait}>
                              {/* {x.result == undefined ? 'ToDo' : x.result === 1 ? t('failed') : t('passed')} */}
                              {x.showResult === 2 ? (x.result == undefined ? 'ToDo' : x.result === 1 ? t('failed') : t('passed')) : t('awaited')}
                              <br />
                              {x.showResult == 2 ? x.actualScore ?? '--' : '--'}/{x.totalScore ?? 0}

                            </div>
                            <h4 className={styles.notificationTextTitle}>{isMobile ? x.name?.substr(0, 13) : x.name}<span style={{ float: 'right', marginInline: '1px' }}>
                              <Tooltip classes={{ tooltip: classes.tooltip }} title="Play">
                                <button
                                  className={x.maxAttempts <= x.attemptNumber ?? 0 ? classes.enabledButton : classes.defaultButton}
                                  style={{ borderRadius: '10px', padding: '0px 7px 0px 7px', color: 'white', border: 'none', margin: '0px 6px 0px 0px', whiteSpace: 'nowrap' }}
                                  onClick={() => {
                                    setTestIndex(i);
                                    showTestModelNew(
                                      i,
                                      x.testId,
                                      (x.attemptNumber ?? 0) + 1,
                                      x.name,
                                    );
                                  }}
                                  disabled={x.maxAttempts <= x.attemptNumber ?? 0}>
                                  {x.maxAttempts <= x.attemptNumber ?? 0
                                    ? <PlayArrow />
                                    : <PlayArrow />}
                                </button>
                              </Tooltip>
                              <Tooltip classes={{ tooltip: classes.tooltip }} title="Preview">
                                <buton
                                  className={x.attemptNumber <= 0 || x.showResult == 1 ? classes.disablebtn : classes.view}
                                  onClick={x.attemptNumber <= 0 || x.showResult == 1 ? '' : () => showEvaluationModel(x.testId, x.name)}

                                >
                                  <Visibility style={{ margin: '3px', marginBottom: '6px' }} />
                                </buton>
                              </Tooltip>
                              <Tooltip classes={{ tooltip: classes.tooltip }} title="Analytics">
                                <buton
                                  className={x.attemptNumber <= 0 || x.showResult == 1 ? classes.disabledanalyticsbtn : classes.analyticsbtn}
                                  disabled={x.attemptNumber <= 0}
                                  onClick={x.attemptNumber <= 0 || x.showResult == 1 ? '' : () => showAnalytics(x.testId, x.name)}
                                >
                                  <AnalyticsRounded style={{ margin: '3px', marginBottom: '6px' }} />
                                </buton>
                              </Tooltip>
                            </span></h4>
                            <h5 className={styles.notificationText}><small> {t("lastAttempt")}- {!x.attemptedOn || x.attemptedOn === null || x.attemptedOn === undefined ? <span style={{ fontStyle: 'italic', color: '#0EB6CA' }}> {t("YetToAttempt")}</span> : <Moment style={{ fontStyle: 'italic', color: '#0EB6CA' }} format="DD-MMM-YYYY">{x.attemptedOn}</Moment>}, <span>{t("attempts")}- <span style={{ fontStyle: 'italic', color: '#0EB6CA' }}> {x.attemptNumber ?? 0}/{x.maxAttempts ?? 1}</span></span> </small></h5>

                          </Card>
                        )) :
                      <Card className={styles.card}>
                        <div className={styles.floatingHeadWait}>
                          <Moment format="MMM">
                            {new Date()}
                          </Moment>
                          <br />
                          <Moment format="DD">
                            {new Date()}
                          </Moment>
                        </div>
                        <h4 className={styles.notificationTextTitle}>{t('TestsNotFound')}</h4>
                      </Card>
              }
            </GridItem>
          </GridContainer>

          {testPlayerModal}
          {evaluationModel}
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default TestViewer;
