/* eslint-disable import/first */
import 'katex/dist/katex.min.css';
import React, { useContext } from "react";
import Latex from 'react-latex-next';
{/* latex CSS */ }
// material-ui
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyle from "../../assets/dashboardStyle";
import loginPageStyle from "../../assets/loginPageStyle";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Divider from "@material-ui/core/Divider";
import { CachedOutlined, ImportContactsSharp } from '@material-ui/icons';
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from '@mui/material';
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from 'react-i18next';
import Bookmark from "../../Bookmark/Bookmark";
import Hint from "../../Hint/Hint";
import radioStyles from "../../assets/customCheckboxRadioSwitch";
import { TestContext } from "../../contexts/TestContext";
import questionstyles from '../../styles/QuestionTypes.module.css';
import Button from '../CustomButtons/Button';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Solution from '../Solution/Solution';

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  ...radioStyles,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  tooltip: {
    fontSize: "1.5em",
  },
};

const useStyles = makeStyles(styles);

export default function SingleChoiceQuestion({ qNoes, question, questionObj, contentType, updateQuestion, preview }) {
  const { t } = useTranslation();
  const { test, selectEvent, setSelectEvent } = useContext(TestContext);
  console.log("question123", test);
  console.log(questionObj, 'questionObj123')

  const [selectedEnabled, setSelectedEnabled] = React.useState(null);
  const [showSolution, setShowSolution] = React.useState(false);

  const handleCheck = (x) => {
    console.log("Before handleCheck:setSelectedEnabled", x);
    setSelectedEnabled(x);
    setSelectEvent(selectEvent == true ? false : true)
    question.response = x;
    question.isCorrect = x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') == question.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '');
    console.log("handleCheck:question", question);
  };
  const onClearHandle = () => {
    setSelectedEnabled(null);
    question.response = null;
    setSelectEvent(selectEvent == true ? false : true)
  }
  React.useEffect(() => {
    //needs to be clear to avoid retaining of options
    setSelectedEnabled(null);
    setSelectedEnabled(question.response);
    console.log("useEffect:question", question);
  }, [question]);

  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" p={2}>
        <Box p={2} sx={{ width: "100%" }}>
          <GridContainer alignItems="center" spacing={1}>
            <GridItem xs={5} sm={5} md={5}>
              <div style={{ float: 'left', marginRight: '5px', fontSize: '21px' }}> Question {qNoes}. </div>
            </GridItem>
            <GridItem xs={7} sm={7} md={7}>
              <Box p={1} style={{ float: 'right' }}>
                {
                  preview != true ?
                    <Tooltip title={t('clearSol')} classes={{ tooltip: classes.tooltip }} placement="bottom">

                      < CachedOutlined onClick={onClearHandle} style={{ color: "orange", fontSize: 30 }} />
                    </Tooltip>
                    : null
                }
                {
                  preview == true
                    ? <h6 style={{ float: 'right', color: '#fc940c' }}> {` ${t('Section')} : ${question?.poolText}`}</h6> : null
                }
                {preview != true && test && test.showHint == 2 ? (
                  <Bookmark question={question} message={questionObj[0]?.Hint} questionId={question.id} />
                ) : null}

                {preview != true && test && test.showHint == 2 ? (
                  <Hint question={question} message={questionObj[0]?.Hint} contentType={contentType} questionId={question.id} />
                ) : null
                }
              </Box>
            </GridItem>
          </GridContainer>

          <Box display="flex" p={1} style={{ padding: 0 }}>
            <Box p={1} flexGrow={1} style={{ padding: 0, float: 'left', fontSize: '22px' }}>
              {
                question && questionObj[0] && questionObj[0]?.Text != null && questionObj[0]?.Text?.length > 0 ? (contentType == 1 ?
                  <div style={{ color: 'black', fontSize: '20px', marginBottom: '10px' }}>{questionObj[0]?.Text}</div>
                  : contentType == 3 ?
                    <Latex style={{ color: 'black' }}>{questionObj[0]?.Text}</Latex>
                    :
                    contentType == 4 ?
                      <div style={{ color: 'black', fontSize: '20px' }}>{questionObj[0]?.Text && ReactHtmlParser(questionObj[0]?.Text)}</div>
                      : <div style={{ color: 'black', fontSize: '20px', marginBottom: '10px' }}>{questionObj[0]?.Text}</div>
                )
                  : null
              }
            </Box>
          </Box>

          <Box p={1}>
            {questionObj[0]?.Image && <img src={questionObj[0]?.Image} alt="..." className={questionstyles.Questionimage} />}
          </Box>
          <GridContainer alignItems="center" spacing={1}>
            {/* {console.log(x === selectedEnabled, 'selectedEnabled')} */}
            {question?.options["$values"]
              .filter((x) => x != null)
              .map((x, i) => (
                <GridItem xs={11} sm={6} md={6} >
                  <GridContainer spacing={1} key={i}>
                    <GridItem xs={8} sm={8} md={10}>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                              onChange={() => handleCheck(x)}
                              value="a"
                              name="radio button enabled"
                              aria-label="A"
                              icon={
                                <FiberManualRecord
                                  className={classes.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classes.radioChecked}
                                />
                              }
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot,
                              }}
                              disabled={question.isSubmitted == true}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={
                            contentType == 1 ?
                              x : contentType == 3 ?
                                <Latex style={{ color: 'black' }}>{x}</Latex> :
                                contentType == 4 ?
                                  ReactHtmlParser(x) :
                                  x
                          }
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} style={{ alignSelf: 'center' }}>
                      {(question.isSubmitted == true || preview == true) && question.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                        <GridItem xs={11} sm={11} md={6}>
                          <CheckIcon style={{ color: "green" }} />
                        </GridItem>
                      ) : null}

                      {question.isSubmitted == true &&
                        question.isCorrect == false &&
                        selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                        <GridItem xs={11} sm={11} md={6}>
                          <CloseIcon style={{ color: "red" }} />
                        </GridItem>
                      ) : null}
                    </GridItem>
                  </GridContainer>
                  <GridItem xs={11} sm={11} md={11}>
                    <Divider />
                  </GridItem>
                </GridItem>
              ))
            }
          </GridContainer>
          <div style={{ marginBottom: '4%' }}>
            {
              preview == true ?
                <Button color="primary" onClick={() => setShowSolution(showSolution ? false : true)}>
                  <ImportContactsSharp style={{ marginRight: '5px' }} />
                  {
                    showSolution ? t('hideSolution') : t('showSolution')
                  }
                </Button>
                : null
            }
            {
              showSolution ?
                <Solution
                  key={qNoes}
                  question={question}
                  questionObj={questionObj}
                  contentType={question.contentType}
                  updateQuestion={() => { }}
                  preview={preview}
                />
                : null
            }
          </div>

        </Box>
      </Box>
    </div>
  );
}
