import axios from 'axios';
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../contexts/LoginContext';
import CustomSelect from './CustomSelect/CustomSelect';

export default function SwitchProvider(props) {
    const { providerlist } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setProviderName, setProviderId, setPageKey, setUserId } = useContext(LoginContext);
    const selectedProvider = (v, x) => {
        console.log(v, 'isV', x, 'isX')
        // setAttemptId(v);
        // console.log(providerIdByUrl, "check me again");
        setProviderId(v);
        setUserId(x.map((x) => x.userId));
        setProviderName(x.map((x) => x.providerName));
        axios.defaults.headers.common = {
            providerId: v,
        };
        // if (sessionStorage.getItem('login') == true) {
        //     setPageKey(1);
        // } else {
        sessionStorage.setItem("login", true);
        console.log("navigatingggg  from signup");
        navigate("/Home");
        // }

    }
    return (
        <div style={{ padding: '7% 5% 5% 5%', height: 100 }}>
            {
                providerlist.length === 1 ?
                    selectedProvider(providerlist[0]?.id, providerlist.filter((x) => x.id == providerlist[0]?.id)) :
                    <CustomSelect
                        options={providerlist.map((x) => ({ id: x.id, name: x.providerName }))}
                        value={1}
                        setvalue={(v) => {
                            console.log(v, 'vvvkkk');
                            selectedProvider(v, providerlist.filter((x) => x.id == v));
                            // setAttemptId(v);
                            // getQuestions(v);
                        }}
                        label={t('selectProvider')}
                    />

            }
        </div>
    )
}
