/* eslint-disable import/first */
import 'katex/dist/katex.min.css';
import React, { useContext } from "react";
import Latex from 'react-latex-next';
{/* latex CSS */ }
// material-ui
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyle from "../../assets/dashboardStyle";
import loginPageStyle from "../../assets/loginPageStyle";
// @material-ui/icons
// core components
import { Tooltip } from '@material-ui/core';
import { CachedOutlined, ImportContactsSharp } from '@material-ui/icons';
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from 'react-i18next';
import Bookmark from "../../Bookmark/Bookmark";
import Hint from "../../Hint/Hint";
import radioStyles from "../../assets/customCheckboxRadioSwitch";
import { TestContext } from "../../contexts/TestContext";
import questionstyles from '../../styles/QuestionTypes.module.css';
import Button from '../CustomButtons/Button';
import CustomInput from '../CustomInput/CustomInput';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Solution from '../Solution/Solution';

var styles = {
    ...dashboardStyle,
    ...loginPageStyle,
    ...radioStyles,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

const useStyles = makeStyles(styles);

export default function NumericalTypeQuestion({ qNoes, question, questionObj, contentType, updateQuestion, preview }) {
    const { t } = useTranslation();

    const { test, selectEvent, setSelectEvent } = useContext(TestContext);
    const [showSolution, setShowSolution] = React.useState(false);
    const [selectedEnabled, setSelectedEnabled] = React.useState('');

    console.log("selectedEnabled123", selectedEnabled);

    const handleCheck = (x) => {
        setSelectedEnabled(x.target.value?.trim());
        console.log(x.target.value == x.target.value?.trim(), 'selectedResp')
        question.response = x.target.value?.trim();
        question.isCorrect = x.target.value?.trim() == question.answer;
        setSelectEvent(selectEvent == true ? false : true)
    };
    const onClearHandle = () => {
        setSelectedEnabled('');
        question.response = '';
        setSelectEvent(selectEvent == true ? false : true)
    }

    React.useEffect(() => {
        //needs to be clear to avoid retaining of options
        if (question.response) {
            setSelectedEnabled(question.response)
        } else {
            setSelectedEnabled('')
        }
    }, [question]);

    const classes = useStyles();

    return (
        <div style={{ width: "100%" }}>
            <Box display="flex" p={2}>
                <Box p={2} sx={{ width: "100%" }}>
                    <GridContainer alignItems="center" spacing={1}>
                        <GridItem xs={5} sm={5} md={5}>
                            <div style={{ float: 'left', marginRight: '5px', fontSize: '21px' }}> Question {qNoes}. </div>
                        </GridItem>
                        <GridItem xs={7} sm={7} md={7}>
                            <Box p={1} style={{ float: 'right' }}>
                                {
                                    preview != true ?
                                        <Tooltip title={t('clearSol')} classes={{ tooltip: classes.tooltip }} placement="bottom">
                                            < CachedOutlined onClick={onClearHandle} style={{ color: "orange", fontSize: 30 }} />
                                        </Tooltip>
                                        : null
                                }
                                {
                                    preview == true
                                        ? <h6 style={{ float: 'right', color: '#fc940c' }}> {` ${t('Section')} : ${question?.poolText}`}</h6> : null
                                }
                                {preview != true && test && test.showHint == 2 ? (
                                    <Bookmark question={question} message={questionObj[0]?.Hint} questionId={question.id} />
                                ) : null}
                                {preview != true && test && test.showHint == 2 ? (
                                    <Hint question={question} message={questionObj[0]?.Hint} contentType={contentType} questionId={question.id} />
                                ) :
                                    null
                                }
                            </Box>
                        </GridItem>
                    </GridContainer>
                    {/* <Box p={1} style={{ float: 'right' }}>
            </Box> */}

                    <Box display="flex" p={1} style={{ padding: 0 }} >
                        <Box p={1} flexGrow={1} style={{ padding: 0, float: 'left', fontSize: '22px' }}>
                            {
                                question && questionObj[0]?.Text && questionObj[0]?.Text != null && questionObj[0]?.Text?.length > 0 ? (contentType == 1 ?
                                    <div style={{ color: 'black', fontSize: '20px', marginBottom: '10px' }}>{questionObj[0]?.Text}</div>
                                    : contentType == 3 ?
                                        <Latex style={{ color: 'black' }}>{questionObj[0]?.Text}</Latex>
                                        :
                                        contentType == 4 ?
                                            <div style={{ color: 'black', fontSize: '20px' }}>{questionObj[0]?.Text && ReactHtmlParser(questionObj[0]?.Text)}</div>
                                            : <div style={{ color: 'black', fontSize: '20px', marginBottom: '10px' }}>{questionObj[0]?.Text}</div>
                                )
                                    : null
                            }
                        </Box>
                    </Box>

                    <Box p={1} >
                        {questionObj[0]?.Image && <img src={questionObj[0]?.Image} alt="..." className={questionstyles.Questionimage} />}
                    </Box>

                    {console.log(question, 'inMpa123')}
                    <GridContainer alignItems="center" key={question["$id"]} spacing={1}>
                        <GridItem xs={11} sm={11} md={5} key={'input_1' + question["$id"]}>
                            <div
                                className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                }
                            >
                                <CustomInput
                                    labelText={question.isSubmitted == true || preview == true ? null : <span> Type your answer</span>}
                                    id="response"
                                    formControlProps={{
                                        fullWidth: false,
                                    }}
                                    inputProps={{
                                        name: 'response',
                                        onChange: (x) => handleCheck(x),
                                        type: "number",
                                        value: selectedEnabled
                                    }}
                                    disabled={question.isSubmitted == true || preview == true ? true : false}
                                />
                                {
                                    (question.isSubmitted == true || preview == true) && question.isCorrect == true && question.answer === selectedEnabled ?
                                        <CheckIcon style={{ color: "green" }} /> :
                                        null
                                }
                                {question.isSubmitted == true &&
                                    question.isCorrect == false &&
                                    selectedEnabled !== question.answer ? (
                                    question.response == null || question.response == '' ?
                                        'Non attempted'
                                        :
                                        <CloseIcon style={{ color: "red" }} />
                                ) : null}
                            </div>
                        </GridItem>
                        <GridItem xs={11} sm={11} md={5} key={'Input_2' + question["$id"]}>
                            <div
                                className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                }
                            >

                                {
                                    question.isSubmitted == true || preview == true ?
                                        <>
                                            <CustomInput
                                                labelText={<span> Correct Answer</span>}
                                                id="choiceId01"
                                                formControlProps={{
                                                    fullWidth: false,
                                                }}
                                                inputProps={{
                                                    name: 'choiceId01',
                                                    onChange: (x) => handleCheck(x),
                                                    type: "number",
                                                    value: question.answer
                                                }}
                                                disabled={true}
                                            />
                                            {
                                                question.isSubmitted == true || preview == true ?
                                                    <CheckIcon style={{ color: "green" }} /> :
                                                    null
                                            }
                                            {question.isSubmitted == true &&
                                                question.isCorrect == false &&
                                                selectedEnabled === question.answer ? (
                                                question.response == null || question.response == '' ?
                                                    'Non attempted'
                                                    :
                                                    (<CloseIcon style={{ color: "red" }} />)
                                            ) : null}
                                        </>
                                        : null
                                }
                            </div>
                        </GridItem>
                        {/* <GridItem xs={11} sm={11} md={11}>
                            <Divider />
                        </GridItem> */}
                    </GridContainer>
                    <div style={{ marginBottom: '4%' }}>
                        {
                            preview == true ?
                                <Button color="primary" onClick={() => setShowSolution(showSolution ? false : true)}>
                                    <ImportContactsSharp style={{ marginRight: '5px' }} />
                                    {
                                        showSolution ? t('hideSolution') : t('showSolution')
                                    }
                                </Button>
                                : null
                        }
                        {
                            showSolution ?
                                <Solution
                                    key={qNoes}
                                    question={question}
                                    questionObj={questionObj}
                                    contentType={question.contentType}
                                    updateQuestion={() => { }}
                                    preview={preview}
                                />
                                : null
                        }
                    </div>
                </Box>
            </Box>
        </div >
    );
}
