import React, { useState } from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import InfoOutline from "@material-ui/icons/InfoOutline";

// core components
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import GridContainer from "../Components/Grid/GridContainer";
import GridItem from "../Components/Grid/GridItem";



import styles from "../assets/dashboardStyle";

import { Assignment, AssignmentTurnedIn, Help, ListAlt, Timeline } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";


import { PublishedWithChanges } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UseGetTopFiveAnalytics } from "../Components/analyticsHooks/UseGetTopFiveAnalytics";
import { LoginContext } from "../contexts/LoginContext";
import useGetCourseCount from "../testSeriesHooks/useGetCourseCount";
import useGetAssignedTestsDetails from "../testsHooks/useGetAssignedTestsDetails";
import DashboardChart from "./DashboardChart";
import DashboardTable from "./DashboardTable";

const useStyles = makeStyles(styles);

function StudentDashboard(props) {

  const { t } = useTranslation();
  const classes = useStyles();
  const { pageKey, setPageKey, testSeriesStatus, setTestSeriesStatus, setStatus, status, setAssessmet, UserId } = useContext(LoginContext)
  const [topFive, setTopFiveTests] = useState([]);

  const getServices = async () => {
    if (topFive?.length < 1) {
      const testAnalytics = await UseGetTopFiveAnalytics(UserId);
      if (topFive?.length < testAnalytics?.length) {
        console.log("length is greater")
        setTopFiveTests(testAnalytics)
      }
      console.log("consoleanalyticsData", testAnalytics)
    }
  }

  useEffect(() => {
    getServices();
  }, [UserId])

  const { data: details } = useGetAssignedTestsDetails(0);

  const { data: courseCount } = useGetCourseCount();


  const navigate = useNavigate()

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <ListAlt />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                {t('totalTests')}
                {/* ({details ? details.length : "..."}) */}
              </h4>
            </CardHeader>
            <CardFooter stats>
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(6)
                  setTestSeriesStatus("ALL")
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTestSeries')} ({courseCount ? courseCount.total : "..."})</h6>
                {/* <LaunchOutlined className={classes.cardfooterButtonsIcons}/>  */}
              </Button>
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(5)
                  setStatus("ALL")
                  setAssessmet(true)
                }} ><h6 className={classes.cardfooterText}>{t('assignedTests')} ({details ? details.length : "..."})</h6> </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <PublishedWithChanges />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                {t('InProgress')}
              </h4>
            </CardHeader>
            <CardFooter stats>
              {/* {t('seeDetails')} */}
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(6)
                  setTestSeriesStatus("INPROGRESS")
                }} ><h6 className={classes.cardfooterText}>{t('assignedTestSeries')} ({courseCount ? courseCount.inProgress : "..."})</h6> </Button>
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(5)
                  setStatus("INPROGRESS")
                  setAssessmet(true)
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTests')} ({details ? details.filter(x => x.attemptNumber >= 1 && x.attemptNumber < x.maxAttempts).length : "..."})</h6> </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Help />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                {t('pending')}
              </h4>
            </CardHeader>
            <CardFooter stats>
              {/* {t('seeDetails')} */}
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(6)
                  setTestSeriesStatus("PENDING")
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTestSeries')} ({courseCount ? courseCount.pending : "..."})</h6> </Button>
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(5)
                  setStatus("PENDING")
                  setAssessmet(true)
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTests')} ({details ? details.filter(x => x.attemptNumber < 1).length : "..."})</h6> </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                {/* <Check/> */}
                <AssignmentTurnedIn />
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle}>
                {t('completed')}
              </h4>
            </CardHeader>
            <CardFooter stats>
              {/* {t('seeDetails')} */}
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(6)
                  setTestSeriesStatus("COMPLETED")
                }}><h6 className={classes.cardfooterText}>{t('assignedTestSeries')} ({courseCount ? courseCount.completed : "..."})</h6> </Button>
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(5)
                  setStatus("COMPLETED")
                  setAssessmet(true)
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTests')} ({details ? details.filter(x => x.attemptNumber >= x.maxAttempts).length : "..."})</h6> </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card className={classes.lastfiveTestCard}>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('lastFivetests')}</h4>
            </CardHeader>
            <CardBody className={classes.dashboardCardBody}>
              {topFive ? <DashboardTable topFive={topFive} /> : <></>}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('whereareYou')}</h4>
            </CardHeader>
            <CardBody>
              {topFive ? <DashboardChart topFive={topFive} /> : <></>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}
export default StudentDashboard;
