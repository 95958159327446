import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import HomeScreen from "./Components/HomeScreen";
import LetsStart from "./Components/LetsStart";
import ParentDetails from "./Components/ParentDetails";
import PersonalDetails from "./Components/PersonalDetails";
import SignInForm from "./Components/SignInForm";
import SignUpForm from "./Components/SignupForm";
import StudentDashboard from "./Dashboard/StudentDashboard";
import ProtectedRoute from "./ProtectedRoute";
import { configUrl } from "./config";
import { LoginContext } from "./contexts/LoginContext";

/* New Code Starts here */

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from "./Components/ForgotPassword";
import app from './firebase';


/* Ends here*/
function App() {
  const [providerId, setProviderId] = React.useState(0);
  const [providerKey, setProviderKey] = React.useState("");
  const [UserId, setUserId] = React.useState();
  const [providerName, setProviderName] = React.useState("");
  const [UserName, setUserName] = React.useState("");
  const [emailC, setEmailC] = React.useState("");
  const [userDetail, setUserDetail] = React.useState([]);
  const [educationDetails, setEducationDetails] = React.useState([]);
  const [siblingDetail, setSiblingDetail] = React.useState([]);
  const [qIdHint, setQIdHint] = React.useState([]);
  const [status, setStatus] = React.useState("ALL");
  const [testSeriesStatus, setTestSeriesStatus] = React.useState("ALL");
  const [testSeriesObj, setTestSeriesObj] = React.useState([]);
  const [testSeriesCardStatus, setTestSeriesCardStatus] = React.useState("");
  const [topicId, setTopicId] = React.useState();
  const [alertFlag, setAlertFlag] = React.useState(false);
  const [courseId, setCourseId] = React.useState();
  const [assessment, setAssessmet] = React.useState(true);
  const [showToC, setShowTOC] = React.useState(false);
  const [pageKey, setPageKey] = React.useState(0);

  const client = new QueryClient();
  let config = JSON.parse(`{"api":"${configUrl.AssessmentServer}"}`);
  // console.log('local config',config)

  const messaging = getMessaging(app);

  const activateMessages = async () => {
    // console.log('messaging',messaging);
    const token = await getToken(messaging, {
      vapidKey:
        "BFLHmJUchm5llz8UsotJ8ztrq30QWeATAIwEb-Oxl2lEjztAG1qYRWXxkccoQJfLGY2vVbYj0NuQHdUteFT0570",
    }).catch((error) => console.log("error generatin token"));

    if (token) console.log("token", token);
    if (!token) console.log("no token");
  };

  const getPushMessage = () => {
    console.log("console in getMessage");
    onMessage(messaging, (message) => {
      console.log("your message: ", message);
      toast(message.data.details);
      console.log("payload.data.url" + message.data.url);
      //window.location.replace("http://localhost:3000/"+message.data.url)
    });
  };
  //We only run once using react hook useEffect
  React.useEffect(() => {
    activateMessages();
    getPushMessage();
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <QueryClientProvider client={client}>
          <LoginContext.Provider
            value={{
              providerId,
              setProviderId,
              providerKey,
              setProviderKey,
              providerName,
              setProviderName,
              config,
              UserId,
              setUserId,
              setUserName,
              UserName,
              emailC,
              courseId,
              setCourseId,
              assessment,
              setAssessmet,
              testSeriesObj,
              setTestSeriesObj,
              testSeriesCardStatus,
              setTestSeriesCardStatus,
              topicId,
              setTopicId,
              alertFlag,
              setAlertFlag,
              showToC,
              setShowTOC,
              testSeriesStatus,
              setTestSeriesStatus,
              setEmailC,
              userDetail,
              setUserDetail,
              educationDetails,
              setEducationDetails,
              siblingDetail,
              setSiblingDetail,
              qIdHint,
              setQIdHint,
              pageKey,
              setPageKey,
              status,
              setStatus,
            }}
          >
            <Routes>
              <Route path="*" element={<p>Path not resolved</p>} />
              <Route element={<ProtectedRoute Component={LetsStart} />} path="/"></Route>
              <Route element={<LetsStart />} path="/:id"></Route>
              <Route element={<SignInForm />} path="/signIn/*"></Route>
              <Route element={<SignUpForm />} path="/signup/:id"></Route>
              <Route element={<ForgotPassword />} path="/forgotPass/:userId/*"></Route>

              {/* <Route element={<SignUpForm />} path="/signup"></Route> */}
              <Route element={<ProtectedRoute Component={HomeScreen} />} path="/Home"></Route>
              <Route element={<ProtectedRoute Component={PersonalDetails} />} path="/Profileupdate"></Route>
              <Route element={<ProtectedRoute Component={ParentDetails} />} path="/Home/Parentdetails"></Route>
              <Route element={<ProtectedRoute Component={StudentDashboard} />} path="/Home/Dashboard"></Route>

            </Routes>
          </LoginContext.Provider>
        </QueryClientProvider>
      </Router>
    </div>
  );
}

export default App;
