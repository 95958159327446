//import QuestionComponent from "./QuestionComponent";


import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TestContext } from "../../contexts/TestContext";
import Button from "../CustomButtons/Button";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

const FeedbackScreens = ({ test, message, score, totalScore, onHomePage }) => {
  const { t } = useTranslation();
  const { testAttemptDetails } = useContext(TestContext);
  const handleSubmit = () => {
    testAttemptDetails.currentPageIndex = 3;
    let home = 'to conclude Screen'
    onHomePage(home);
  }
  console.log(score, '', "testAttemptDetails conclude screen ", testAttemptDetails)
  return (
    <div>

      {
        score >= test.masteryScore && testAttemptDetails.result == 2 ?
          <Card>
            <CardBody>
              <GridContainer
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ height: 400 }}
              >
                <GridItem xs={12} sm={12} md={3} />
                <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
                  <h2>{test.feedbackmastery}</h2>
                  <h4>{`${t('feedbackScreen')}: ${score}/${totalScore}`}</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} />
                <GridItem xs={12} sm={12} md={3} />
                <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
                  <Button color="primary" round onClick={handleSubmit}>{t('ok')}</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} />
              </GridContainer>
            </CardBody>
          </Card>
          :
          score >= test.passingScore && testAttemptDetails.result == 2 ? //Note.: BSS121-removed not condition for pass feedback not occuring correctly
            <Card>
              <CardBody>
                <GridContainer
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 400 }}
                >
                  <GridItem xs={12} sm={12} md={3} />
                  <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
                    <h2>{test.feedbackPass}</h2>
                    <h4>{`${t('feedbackScreen')}: ${score}/${totalScore}`}</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} />
                  <GridItem xs={12} sm={12} md={3} />
                  <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
                    <Button color="primary" round onClick={handleSubmit}>{t('ok')}</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} />
                </GridContainer>
              </CardBody>
            </Card> :
            <Card>
              <CardBody>
                <GridContainer
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 400 }}
                >
                  <GridItem xs={12} sm={12} md={3} />
                  <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
                    <h2>{message}</h2>
                    <h4>{`${t('feedbackScreen')}: ${score}/${totalScore}`}</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} />
                  <GridItem xs={12} sm={12} md={3} />
                  <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
                    <Button color="primary" round onClick={handleSubmit}>
                      {t('ok')}
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} />
                </GridContainer>
              </CardBody>
            </Card>
      }
    </div>
  );
};

export default FeedbackScreens;
