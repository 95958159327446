import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../Card/Card';
import Charts from '../Chart/Charts';
import GridContainer from '../Components/Grid/GridContainer';
import GridItem from '../Components/Grid/GridItem';
import AnalyticsTable from '../Tables/AnalyticsTable';
import ChartTable from '../Tables/ChartTable';
import styles from '../styles/DetailingTable.module.css';
import UseGetAnalytics2D from './analyticsHooks/UseGetAnalytics2D';
import UseGetGraph from './analyticsHooks/UseGetGraphAnalytics';

export default function Analytics({ lOid, studentId }) {

  const [contentId, setContentId] = useState(1);
  const [subTypeId, setSubTypeId] = useState(1);
  const [graphAnalyticsData, setGraphAnalyticsData] = useState();
  const { t } = useTranslation();

  const getGraphData = async () => {
    const testany = await UseGetGraph(studentId ? studentId : null, lOid)
    setGraphAnalyticsData(testany);
  }
  useEffect(() => {
    getGraphData();
  }, [])

  const { data } = UseGetAnalytics2D(contentId, subTypeId, studentId, lOid);

  const handleContentChange = (event) => {
    setContentId(event.target.value);
  };
  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const handleExecutionChange = (event) => {
    setSubTypeId(event.target.value);
  };
  console.log(graphAnalyticsData, 'findingOjbs')
  // BSS121 will need to take a change like whrough 400 Bad Request
  let analyticsResultErr = "No Record Found"
  return (
    <GridContainer className={styles.container}>

      {
        data && data?.analyticsResult != analyticsResultErr || graphAnalyticsData && graphAnalyticsData?.analyticsResult != analyticsResultErr ?
          <>
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <h4 className={styles.tablefont} style={{ color: '#fb8c00' }}>{t("objective")} - <span style={{ color: 'gray' }}>{graphAnalyticsData && graphAnalyticsData?.objective?.trim().length == 0 ? 'none' : graphAnalyticsData?.objective}</span></h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <h4 className={styles.tablefont} style={{ color: '#fb8c00' }}>{t("targetAudience")} - <span style={{ color: 'gray' }}>{graphAnalyticsData && graphAnalyticsData?.targetAudience?.trim().length == 0 ? 'none' : graphAnalyticsData?.targetAudience}</span></h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <h4 className={styles.tablefont} style={{ color: '#fb8c00', float: 'right' }}>{t("Result")} - <span style={{ color: 'gray' }}>{graphAnalyticsData && graphAnalyticsData?.totalScore == 0 ? 'none' : `${graphAnalyticsData?.actualScore} / ${graphAnalyticsData?.totalScore}`}</span></h4>
            </GridItem>
            {/* chart Table */}
            <GridItem xs={12} sm={12} md={6} lg={5}>
              <Card style={{ cursor: "pointer" }}>
                <ChartTable
                  studentId={studentId}
                  lOid={lOid}
                  analyticsData={graphAnalyticsData && graphAnalyticsData?.analyticsResult ? isJSON(graphAnalyticsData?.analyticsResult) ? JSON.parse(graphAnalyticsData?.analyticsResult) : '' : ''}
                />
              </Card>
            </GridItem>
            {/* Chart  */}
            <GridItem xs={12} sm={12} md={6} lg={5}>
              <Card style={{ cursor: "pointer" }}>
                <Charts
                  studentId={studentId}
                  lOid={lOid}
                  analyticsData={graphAnalyticsData && graphAnalyticsData?.analyticsResult ? isJSON(graphAnalyticsData?.analyticsResult) ? JSON.parse(graphAnalyticsData?.analyticsResult) : '' : ''}
                />
              </Card>

            </GridItem>
            {/* analytics Table */}
            <GridItem styles={{ marginBottom: '5px' }}>
              <h6>{t('analyticsNote')}</h6>
            </GridItem>
            <GridItem xs={12} sm={12} md={10} lg={9}>
              <Box sx={{ minWidth: 100 }}>
                <FormControl style={{ width: "47%" }}>
                  <InputLabel className={styles.tablefont} style={{ color: '#fb8c00' }} id="demo-simple-select">{t("content")}</InputLabel>
                  <Select
                    className={styles.tablefont}
                    labelId="demo-simple-select"
                    id="demo-simple-select"
                    value={contentId}
                    label="Content"
                    onChange={handleContentChange}
                  >
                    <MenuItem value={1}>{t("categorySubject")}</MenuItem>
                    <MenuItem value={2}>{t("dificultyLevel")}</MenuItem>
                    <MenuItem value={3}>{t("questionType")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{ float: 'right', width: "47%" }}>
                  <InputLabel className={styles.tablefont} style={{ color: '#fb8c00' }} id="demo-simple-select-label">{t("execution")}</InputLabel>
                  <Select
                    className={styles.tablefont}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={subTypeId}
                    label="Execution"
                    onChange={handleExecutionChange}
                  >
                    <MenuItem value={1}>{t("score")}</MenuItem>
                    <MenuItem value={2}>{t("questionAttemptRate")}</MenuItem>
                    <MenuItem value={3}>{t("accuracyRate")}</MenuItem>
                    <MenuItem value={4}>{t("duration")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Card style={{ cursor: "pointer" }}>
                <AnalyticsTable
                  analyticsData={data && data?.analyticsResult != analyticsResultErr ? JSON.parse(data?.analyticsResult) : ''}
                />
              </Card>
            </GridItem>
          </>
          : <Card style={{ cursor: "pointer" }}>
            <h5 style={{ textAlign: 'center', fontSize: 'large' }}>{t('pleaseWaitAnalytics')}</h5>
          </Card>
      }
    </GridContainer>
  )
}




