import React from 'react';
import { useTranslation } from "react-i18next";
import TableOfContentsNew from "../../TableOfContaint/TableOfContentsNew";
import Styles from '../../styles/QuestionWrapper.module.css';


export default function TOCDwawer({ tatalQuestionCount, remainingQuestions, bookmarked, QuestionPerPage }) {
    const { t } = useTranslation();

    return (
        <div>

            <div style={{ border: '0.5px solid #ffa726', background: '#F1E6D3', marginRight: '-15px', marginLeft: '-15px' }}>
                <div className={Styles.TableOfContaintHeadingContainer}>
                    <div className={Styles.TableOfContaintHeadingTotal} style={{ marginLeft: '12px' }} >
                        <h6 >{t('total')} </h6>
                        <h6 >{tatalQuestionCount}</h6>
                    </div>
                    <div className={Styles.TableOfContaintHeadingRemaining}>
                        <h6 >{t('remaining')}</h6>
                        <h6 >{remainingQuestions}</h6>
                    </div>
                    <div className={Styles.TableOfContaintHeadingAttempted}>
                        <h6 >{t('attempted')}</h6>
                        <h6 >{tatalQuestionCount - remainingQuestions}</h6>
                    </div>
                    <div className={Styles.TableOfContaintHeading} style={{ marginRight: '12px' }}>
                        <h6 >{t('bookmark')}</h6>
                        <h6 >{bookmarked}</h6>
                    </div>
                </div>
                <div>
                    {QuestionPerPage == 1 ?
                        <TableOfContentsNew /> : null}
                </div>
            </div>

        </div>
    )
}
