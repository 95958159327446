import { Alert, Snackbar } from "@mui/material";
import {
  Button,
  Card,
  Form,
  Input,
  Select
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoginContext } from "../contexts/LoginContext";
import { getEducationBoard, getProfession } from "../services/dropdownService";
import {
  onParentInfo,
  onSiblingInfo
} from "../services/parentService";
import styles from "../styles/signin.module.css";

const ParentDetails = () => {
  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 20,
    },
  };
  const formItemLayout1 = {
    labelCol: {
      span: 15,
    },
    wrapperCol: {
      span: 15,
    },
  };
  const { t } = useTranslation();
  const dateFormat = "DD/MM/YYYY";
  const { setProviderId, providerId, user, UserId, userDetail, siblingDetail, setUserDetail, setSiblingDetail } = useContext(LoginContext);
  const [EduboardData, setEduboardData] = useState([]);
  const [ProfessiondData, setProfessionData] = useState([]);
  const [parentObj, setParentObj] = useState(
    // JSON.parse(localStorage.getItem("ParentInfo"))
    userDetail
  );
  const [siblingObj, setSiblingObj] = useState(
    // JSON.parse(localStorage.getItem("SiblingInfo"))
    siblingDetail

  );
  // const [parentResp, setParentResp] = useState(
  //   JSON.parse(localStorage.getItem("PersonalInfo"))
  // );
  // const [siblingResp, setSiblingResp] = useState(
  //   JSON.parse(localStorage.getItem("SiblingInfo"))
  // );
  const [open, setOpen] = React.useState(false);
  let defalutDta = {
    userId: UserId,
    siblingId: siblingObj ? siblingObj.siblingId : 0,
    providerId: providerId,
    createdBy: user ?? "system",
    updatedBy: user ?? "system",
    createdDate: new Date(),
    updatedDate: new Date(),
    userTypeId: 4,
    isActive: true,
    educationId: 0,
  };
  const getServices = async () => {
    let isActive = true;
    const eduboard = await getEducationBoard("EduBoard", isActive);
    console.log("eduboard", eduboard);
    const profession = await getProfession("Profession", isActive);
    console.log("profession", profession);
    // const getParent = await getstudentData(UserId);
    // const getSibling = await getSiblingData(UserId);
    // console.log(getSibling, "sibling Details");
    // console.log(getParent, "parent Details");
    if (!eduboard && !profession) {
      // & !getParent.fatherName ===null && !getSibling.$values === []
      setProfessionData([]);
      setEduboardData([]);
      // setParentObj([]);
      // setSiblingObj([]);
    } else {
      setEduboardData(eduboard.$values);
      setProfessionData(profession.$values);
      // setParentObj(getParent.fatherName !== undefined && getParent.fatherName !== null ? getParent:null);
      // localStorage.setItem("ParentInfo", JSON.stringify(getParent.fatherName !== undefined && getParent.fatherName !== null ? getParent:null));

      // setSiblingObj(
      //   getSibling.$values[0] !== undefined ? getSibling.$values[0] : null
      // );
      // localStorage.setItem(
      //   "SiblingInfo",
      //   JSON.stringify(
      //     getSibling.$values[0] !== undefined ? getSibling.$values[0] : null
      //   )
      // );
    }
  };
  useEffect(() => {
    setProviderId(providerId);
    // BSS121
    axios.defaults.headers.common = {
      providerId: providerId,
    };

  }, []);
  useEffect(() => {
    getServices();
  }, []);

  const onSave = async (values) => {
    console.log(values, "onSaveValuesssss")
    setOpen(true);
    const g = [values.fatherName, values.fatherProfession, values.fatherEmail, values.fatherMobile];
    let parent = {};
    parent.fatherName = values.fatherName
    parent.fatherProfession = values.fatherProfession
    parent.fatherMobile = values.fatherMobile
    parent.fatherEmail = values.fatherEmail

    let sibling = {};
    sibling.siblingName = values.siblingName
    sibling.dob = values.dob
    parent.education = values.education
    let parentPayload = Object.assign(parentObj, parent);
    const updateParent = await onParentInfo(parentPayload);
    console.log(updateParent, 'updateParentResponse')
    if (updateParent?.response?.status == 200) {
      setUserDetail(updateParent);
    }
    // localStorage.setItem("ParentInfo", JSON.stringify(updateParent));
    let siblingPayload = Object.assign(defalutDta, sibling);
    const updateSibling = await onSiblingInfo(siblingPayload);
    console.log(updateSibling, 'updateParentResponse')
    if (updateParent?.response?.status == 200) {
      setSiblingDetail(updateSibling.$values[0]);
    }
    // localStorage.setItem(
    //   "SiblingInfo",
    //   JSON.stringify(updateSibling.$values[0])
    // );
    console.log(values);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className="container">
      <Card style={{ backgroundColor: "antiquewhite" }}>
        <Form
          name="normal_signup"
          className="signUpForm"
          onFinish={(values) => onSave(values)}
          //  BSS121
          initialValues={{
            fatherName: parentObj ? parentObj.fatherName !== null ? `${parentObj.fatherName}` : "" : "",
            fatherProfession: parentObj ? parentObj.fatherProfession !== 0 ? `${parentObj.fatherProfession}` : "" : "",
            fatherEmail: parentObj ? parentObj.fatherEmail !== null ? `${parentObj.fatherEmail}` : "" : "",
            fatherMobile: parentObj ? parentObj.fatherMobile !== null ? `${parentObj.fatherMobile}` : "" : "",
            siblingName: siblingObj ? `${siblingObj.siblingName}` : "",
            dob: siblingObj ? moment(`${siblingObj.dob}`) : "",
            education: siblingObj ? `${siblingObj.education}` : "",
          }}
        >
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open}
            onClose={handleClose}
            autoHideDuration={1000}
          >
            <Alert
              severity="success"
              onClose={handleClose}
              sx={{ fontSize: "15px", width: "100%" }}
            >
              {t("userUpdated")}
            </Alert>
          </Snackbar>
          <h4
            style={{
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              color: "#EC9706",
              textAlign: "left",
              marginBottom: "25px",
            }}
          >
            {t("parentdetails")}
          </h4>
          <div className="row">
            <div className="col-sm">
              <label className={styles.label} for="fatherName">Parent Name</label>
              <Form.Item
                {...formItemLayout}
                name="fatherName"
                rules={[
                  {
                    required: true,
                    message: t("reqFathername")
                  },
                ]}
              >
                <Input placeholder="Parent Name" />
              </Form.Item>
            </div>
            <div className="col-sm">
              <label className={styles.label} for="fatherProfession">Profession</label>

              <Form.Item
                {...formItemLayout}
                name="fatherProfession"
                rules={[
                  {
                    required: false,
                    message: t("reqParentProfession")
                  },
                ]}
              >
                <Select
                  style={{ textAlign: "left" }}
                  placeholder="Profession"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {ProfessiondData && ProfessiondData.map((st, index) => {
                    console.log(ProfessiondData);
                    return (
                      <option
                        value={`${ProfessiondData[index].valueInt}`}
                      >{`${ProfessiondData[index].valueString}`}</option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm">
              <label className={styles.label} for="fatherEmail">Email Id</label>

              <Form.Item
                {...formItemLayout}
                name="fatherEmail"
                rules={[
                  {
                    type: "email",
                    message: t("reqEmailValid"),
                  },
                  {
                    required: true,
                    message: t("@reqEmail"),
                  },
                ]}
              >
                <Input placeholder="Email ID" />
              </Form.Item>
            </div>
            <div className="col-sm">
              <label className={styles.label} for="fatherMobile">Contact</label>

              <Form.Item
                {...formItemLayout}
                name="fatherMobile"
                rules={[
                  {
                    required: true,
                    message: t("@reqMobile")
                  },
                ]}
              >
                <Input placeholder="Contact" />
              </Form.Item>
            </div>
          </div>

          {/* <h4
            style={{
              fontFamily:'Roboto, Helvetica, Arial, sans-serif',
              color: "#EC9706",
              textAlign: "left",
              marginBottom: "25px",
            }}
          >
            {t("siblingdetails")}
          </h4> */}

          {/* <div className="row">
            <div className="col-sm">
            <label className={styles.label} for="siblingName">Name</label>

              <Form.Item
                {...formItemLayout1}
                name="siblingName"
                rules={[
                  {
                    // required: true,
                    // message:t("siglingName")
                  },
                ]}
              >
                <Input placeholder="Name" />
                {

                //  <TextField id="outlined-basic" label="Name" variant="outlined" /> 
                }
              </Form.Item>
            </div>
            <div className="col-sm">
            <label className={styles.label} for="dob">Date of Birth</label>

              <Form.Item
                {...formItemLayout1}
                name="dob"
              >
                <DatePicker
                  style={{ width: "-webkit-fill-available" }}
                  placeholder="Date of Birth"
                  format={dateFormat}

                />
              </Form.Item>
            </div>

            <div className="col-sm">
            <label className={styles.label} for="education">Sibling Education</label>

              <Form.Item
                {...formItemLayout1}
                name="education"
                rules={[
                  {
                    // required: true,
                    // message:t("@reqBoard")
                  },
                ]}
              >
                <Select
                  style={{ textAlign: "left" }}
                  placeholder="Sibling Education"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {EduboardData.map((st, index) => {
                    return (
                      <option
                        value={`${EduboardData[index].valueInt}`}
                      >{`${EduboardData[index].valueString}`}</option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div> */}

          <Form.Item>
            <Button
              style={{
                backgroundColor: "#EC9706",
                color: "#ffff",
                marginBottom: "22px",
                float: 'left'
              }}
              htmlType="submit"
            >
              {t("saveBtn")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ParentDetails;
